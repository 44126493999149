@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'neue-haas';
  src: url('/fonts/NeueHaasDisplay-Roman.woff') format('woff');
}

html, body {
  @apply bg-offwhite text-slate font-neue antialiased;
}

@layer {
  html {
    font-family: "neue-haas";
  }
}

/* video.js */
.vjs-big-play-button {
  @apply !w-16 !h-16 !leading-[64px] !text-[24px] !border !border-white !rounded-full !bg-[rgba(255,255,255,0)] !m-0 !translate-x-[-50%] !translate-y-[-50%];
}

.vjs-control-bar, .video-react-control-bar {
  @apply !bg-[rgba(255,255,255,0)] !border-none !p-0 !m-0 !opacity-100;
}

.vjs-volume-panel {
  @apply !min-w-[150px] !absolute !h-[32px] !bottom-0 !left-0;
}

.vjs-volume-horizontal {
  @apply !opacity-100 !w-20;
}

.vjs-progress-control {
 @apply !absolute !bottom-2.5 !top-2.5 !right-2.5 !w-[80%] !h-[24px];
}

.vjs-fullscreen-control {
  @apply !absolute !bottom-0 !right-0 !m-0 !h-8;
}

/* video-react */
.video-react-progress-control {
  /* @apply mr-10; */
  /* @apply !absolute !top-[-0.5rem] !left-[calc(50%_-_-1rem_-_24px)] !w-[calc((50vw/1.778)_-_5rem)] rotate-90 origin-top-right; */
}

.video-react-poster, .video-react {
  @apply mobile-up:!bg-[transparent];
}

.video-react-play-progress {
  @apply after:rotate-[-90deg] after:text-sm after:font-sans after:!hidden;
}

.video-react-big-play-button {
  @apply !w-16 !h-16 !leading-[64px] !text-[34px] !border !border-white !rounded-full !bg-[rgba(255,255,255,0)] !m-0 !translate-x-[-50%] !translate-y-[-50%];
}
